import React from 'react';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';

const App = () => {
    const clientId = '1672719905-oeqqruf2lnpbmd1qmn63qa4fnrsakipi.apps.googleusercontent.com'; // Replace with your Google Client ID

    const login = useGoogleLogin({
        flow: 'auth-code',
        onSuccess: async (codeResponse) => {
            console.log('Authorization Code:', codeResponse.code);

            try {
                //const response = await axios.get('https://corpfinhub.co.uk/v1/auth/google/be/login');
				const response = await axios.post('https://lara10.easytodo.in/api/google-login', {
                    code: codeResponse.code,
                });
                console.log('Backend Response:', response.data);
                // Handle the response (e.g., save tokens or user info in localStorage)
            } catch (error) {
                console.error('Error exchanging code:', error);
            }
        },
        onError: (error) => console.error('Login Failed:', error),
    });

    return (
        <div className="App">
            <h1>Google Login with Laravel Backend</h1>
            <button onClick={login}>Sign in with Google</button>
        </div>
    );
};

const MainApp = () => {
    const clientId = '1672719905-oeqqruf2lnpbmd1qmn63qa4fnrsakipi.apps.googleusercontent.com'; // Replace with your Google Client ID

    return (
        <GoogleOAuthProvider clientId={clientId}>
            <App />
        </GoogleOAuthProvider>
    );
};

export default MainApp;